<div class="page" id="installation-page">
  <app-breadcrumb
    [items]="breadcrumbItems"
    [showAreaPlaceholder]="isBusy"
  ></app-breadcrumb>

  <app-page-header
    [title]="installation?.name ?? ''"
    [info]="installation | installationUnits"
    infoIcon="assets/icons/ic_info_outlined.svg"
    [showAreaPlaceholder]="isBusy"
  >
    <dx-button
      type="default"
      stylingMode="outlined"
      icon="download"
      (click)="showImportUnitsPopup()"
      [text]="'ImportUnits.ImportFromFile' | i18next"
      [disabled]="!hasFullAccess"
    >
    </dx-button>
    <dx-button
      type="default"
      icon="favorites"
      [text]="'General.Customize' | i18next"
      (click)="editInstallationDetails()"
      [disabled]="!hasFullAccess"
    ></dx-button>
  </app-page-header>

  <div *ngIf="displayLicensesCard">
    <app-widget-card
      [showAreaPlaceholder]="isBusy"
      minHeight="400px"
      icon="assets/icons/ic_license.svg"
      actionIcon="add"
      [actionEnabled]="hasFullAccess"
      [title]="'Installations.Licenses' | i18next"
      [action]="'General.Add' | i18next"
      (actionClick)="addLicense()"
    >
      <app-licenses-list
        [installationLicenses]="installationLicenses"
      ></app-licenses-list>
    </app-widget-card>
  </div>

  <div>
    <app-widget-card
      icon="assets/icons/ic_device.svg"
      [title]="'General.Devices' | i18next"
      [showAreaPlaceholder]="isBusy"
      height="420px"
    >
      <dx-data-grid
        [dataSource]="installationDevices || []"
        [hoverStateEnabled]="true"
        (onRowClick)="editDeviceDetails($event)"
      >
        <dxo-paging [pageSize]="4"></dxo-paging>

        <dxi-column dataField="name"> </dxi-column>
        <dxi-column
          [caption]="'General.Outputs' | i18next"
          cellTemplate="templateOutputs"
        >
          <div *dxTemplate="let cellInfo of 'templateOutputs'">
            {{ cellInfo.data | farfisaDeviceToOutputsString }}
          </div>
        </dxi-column>

        <dxi-column
          dataField="product_line"
          [caption]="'General.DeviceLine' | i18next"
          alignment="left"
          cellTemplate="productLineTemplate"
        >
          <div *dxTemplate="let data of 'productLineTemplate'">
            {{ data.value | productLineName }}
          </div>
        </dxi-column>
        <dxi-column
          name="product-type"
          dataField="product_line"
          [caption]="'General.DeviceType' | i18next"
          alignment="left"
          cellTemplate="productTypeTemplate"
        >
          <div *dxTemplate="let data of 'productTypeTemplate'">
            {{ data.value | productTypeName }}
          </div>
        </dxi-column>

        <dxi-column
          dataField="thing_name"
          [caption]="'General.SerialNumber' | i18next"
          cellTemplate="serialNumberTemplate"
        >
          <div *dxTemplate="let data of 'serialNumberTemplate'">
            {{ data.value | thingNameToSerialNumber }}
          </div>
        </dxi-column>

      </dx-data-grid>
    </app-widget-card>
  </div>

  <div class="two-column-layout">
    <div class="left-column">
      <app-widget-card
        icon="assets/icons/ic_home.svg"
        actionIcon="add"
        [title]="'General.Units' | i18next"
        [action]="'General.Add' | i18next"
        [actionEnabled]="hasFullAccess"
        (actionClick)="createUnit()"
        minHeight="1022px"
        [showAreaPlaceholder]="isBusy"
      >
        @if (installationUnits.length > 0) {

        <dx-tag-box
          id="devices-tag-box"
          [items]="installationDevices"
          [(selectedItems)]="filteringDevices"
          displayExpr="name"
          valueExpr="id"
          stylingMode="outlined"
          [placeholder]="'Installations.DevicesTagBoxPlaceholder' | i18next"
        >
        </dx-tag-box>

        <dx-data-grid
          [dataSource]="installationUnits | filterByDevice : filteringDevices"
          id="installation-unit-grid"
          [hoverStateEnabled]="true"
          (onRowClick)="navigateToUnitDetails($event)"
        >
          <dxo-search-panel
            [visible]="true"
            width="100%"
            [placeholder]="'General.Search' | i18next"
          ></dxo-search-panel>

          <dxi-column cellTemplate="iconCellTemplate" width="auto">
            <svg-icon
              src="assets/icons/ic_home.svg"
              class="flex small-icon fill-accent"
              *dxTemplate="let data of 'iconCellTemplate'"
            ></svg-icon>
          </dxi-column>

          <dxi-column
            [caption]="'General.Name' | i18next"
            dataField="name"
            alignment="center"
          >
          </dxi-column>

          <dxi-column
            dataField="alias"
            [caption]="'Units.Alias' | i18next"
            [sortingMethod]="aliasSortingMethod"
            width="100px"
            alignment="center"
          >
          </dxi-column>

          <dxi-column dataField="users" [visible]="false"> </dxi-column>

          <dxi-column
            dataField="devices"
            [allowSearch]="true"
            [caption]="'General.Devices' | i18next"
            alignment="center"
            cellTemplate="devicesCellTemplate"
          >
            <div
              class="text-xs text-gray"
              *dxTemplate="let data of 'devicesCellTemplate'"
            >
              {{ data.value }}
            </div>
          </dxi-column>
        </dx-data-grid>

        } @else {
        {{ "Installations.NoUnitsAvailable" | i18next }}
        }
      </app-widget-card>
    </div>

    <div class="right-column">
      <app-widget-card
        icon="assets/icons/ic_map.svg"
        actionIcon="edit"
        [title]="'Installations.Location' | i18next"
        [action]="'General.Edit' | i18next"
        [actionEnabled]="hasFullAccess"
        subtitleIcon="assets/icons/ic_marker.svg"
        [subtitle]="installation?.address | fullAddress"
        [info]="installation?.description"
        infoIcon="assets/icons/ic_info_outlined.svg"
        [bodyWithoutPadding]="true"
        [showAreaPlaceholder]="isBusy"
        height="600px"
        (actionClick)="editInstallationLocation()"
      >
        <dx-map
          #map
          [markers]="markers"
          [zoom]="14"
          [height]="'100%'"
          width="100%"
          provider="google"
          [apiKey]="googleMapsApiKey"
          markerIconSrc="assets/icons/ic_marker.svg"
        >
        </dx-map>
      </app-widget-card>

      <app-widget-card
        icon="assets/icons/ic_manager.svg"
        actionIcon="assets/icons/ic_ticket_white.svg"
        [title]="'General.Managers' | i18next"
        [action]="'Invitations.InviteManager' | i18next"
        [showAreaPlaceholder]="isBusy"
        minHeight="390px"
        *ngIf="installationUsers"
        (actionClick)="createManagerInvitation()"
      >
        <dx-data-grid
          [dataSource]="installationUsers"
          [hoverStateEnabled]="true"
          (onRowClick)="showUserOrInvitation($event)"
        >
          <dxi-column dataField="email" cellTemplate="emailCellTemplate">
            <app-label
              [text]="data.data.email"
              [color]="data.data.color"
              *dxTemplate="let data of 'emailCellTemplate'"
            ></app-label>
          </dxi-column>

          <dxi-column
            [caption]="'Users.AccessLevel' | i18next"
            cellTemplate="templateAccessLevel"
            alignment="center"
          >
            <div *dxTemplate="let data of 'templateAccessLevel'">
              {{ data.data | userInvitationAccess | i18next }}
            </div>
          </dxi-column>

          <dxi-column
            dataField="actions"
            width="80"
            [allowFiltering]="false"
            [allowSorting]="false"
            cellTemplate="actionsTemplate"
            caption=""
          >
            <svg-icon
              src="assets/icons/ic_arrow_bottom.svg"
              class="action-icon"
              *dxTemplate="let data of 'actionsTemplate'"
            ></svg-icon>
          </dxi-column>
        </dx-data-grid>
      </app-widget-card>
    </div>
  </div>
</div>

<app-shimmer-overlay *ngIf="isBusy"></app-shimmer-overlay>
