import { Component, Input } from '@angular/core';
import { LocalizerService, ScreenService } from '../../shared';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { IntercomStatus } from '../models/iot/intercom_status';
import { IntercomsManager } from '../services/intercoms-manager';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-preferences-settings',
  templateUrl: './preferences-settings.component.html',
  styleUrl: './preferences-settings.component.scss'
})
export class PreferencesSettingsComponent extends BasePageComponent {

  @Input() installationId: string | null = null;

  @Input() unitId: string | null = null;

  @Input() intercomId: string | null = null;

  intercomStatus: IntercomStatus | null = null;

  newIntercomStatus: IntercomStatus | null = null;

  selectedRingtone: { name: string, code: string } | null = null;

  // utility variables

  ringtones = [
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_classic'), code: 'call_ring_0' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_simple'), code: 'call_ring_1' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_dindon'), code: 'call_ring_2' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_sax'), code: 'call_ring_sax_0' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_guitar'), code: 'call_ring_guitar_0' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_marimba'), code: 'call_ring_marimba_0' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_jazz_piano'), code: 'call_ring_piano_0' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_baroque_piano'), code: 'call_ring_piano_1' },
    { name: this.localizer.getLocalizedString('Ringtones.call_ring_light_piano'), code: 'call_ring_piano_2' }
  ];

  saveDetailsButtonDisabled: boolean = false;

  isUpdatingIntercom: boolean = false;

  isRingtonesPopupVisible: boolean = false;

  constructor(private intercomManager: IntercomsManager,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  ngOnInit() {
    if (!this.installationId) {
      this.displayLocalizedErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.unitId) {
      this.displayLocalizedErrorNotification('Units.InvalidUnitId');
      return;
    }

    if (!this.intercomId) {
      this.displayLocalizedErrorNotification('Devices.InvalidIntercomId');
      return;
    }

    this.loadIntercomPreferences();
  }

  onValueChanged(event: any) {
    const component = event.element.id;
    const value = event.value;

    const { audio } = this.intercomStatus!.device.preferences!;
    const currentPreferences = {
      tones_volume: audio!.sounds!.tones_volume,
      ringtones_volume: audio!.sounds!.ringtones_volume,
      calls_volume: audio!.calls!.volume
    };

    if (component === 'slider1') {
      this.newIntercomStatus!.device.preferences!.audio!.sounds!.tones_volume = value;
    } else if (component === 'slider2') {
      this.newIntercomStatus!.device.preferences!.audio!.sounds!.ringtones_volume = value;
    } else if (component === 'slider3') {
      this.newIntercomStatus!.device.preferences!.audio!.calls!.volume = value;
    }

    this.saveDetailsButtonDisabled = this.hasPreferencesChanged(currentPreferences, this.newIntercomStatus!.device.preferences!.audio!);
  }

  updatePreferencesSettings() {
    if (!this.intercomStatus) return;

    this.isUpdatingIntercom = true;
    this.intercomStatus = JSON.parse(JSON.stringify(this.newIntercomStatus));

    const content = this.createUpdatePreferencesContent();

    this.intercomManager.updateIntercomPreferences(this.installationId!, this.unitId!, this.intercomId!, content)
      .pipe(finalize(() => this.isUpdatingIntercom = false))
      .subscribe({
        next: () => {
          this.displayLocalizedSuccessNotification('Preferences.ApiUpdateIntercomPreferencesSuccess');
          this.saveDetailsButtonDisabled = false;
        },
        error: () => this.displayLocalizedErrorNotification('Preferences.ApiUpdateIntercomPreferencesError')
      })
  }

  // utility methods


  showRingtonesPopup(event: any) {
    const element = event.element.id;
    const { call_ringtone, floor_call_ringtone } = this.newIntercomStatus?.device.preferences?.audio?.sounds || {};

    if (element === 'button1') {
      this.selectedRingtone = { name: call_ringtone!, code: 'call_ringtone' };
    } else if (element === 'button2') {
      this.selectedRingtone = { name: floor_call_ringtone!, code: 'floor_call_ringtone' };
    }

    this.isRingtonesPopupVisible = true;
  }

  changeRingtone(event: any) {
    const element_selected = event.selectedRowKeys[0];

    if (this.selectedRingtone?.code === 'call_ringtone') {
      this.newIntercomStatus!.device.preferences!.audio!.sounds!.call_ringtone = element_selected;
    } else if (this.selectedRingtone?.code === 'floor_call_ringtone') {
      this.newIntercomStatus!.device.preferences!.audio!.sounds!.floor_call_ringtone = element_selected;
    }

  }

  closePopup() {
    this.isRingtonesPopupVisible = false;

    this.saveDetailsButtonDisabled =
      this.intercomStatus?.device.preferences?.audio?.sounds?.call_ringtone !==
      this.newIntercomStatus?.device.preferences?.audio?.sounds?.call_ringtone ||
      this.intercomStatus?.device.preferences?.audio?.sounds?.floor_call_ringtone !==
      this.newIntercomStatus?.device.preferences?.audio?.sounds?.floor_call_ringtone;
  }

  // Private methods

  private loadIntercomPreferences() {
    this.isBusy = true;

    this.intercomManager.getIntercomStatus(this.installationId!, this.unitId!, this.intercomId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: intercomStatus => {
          this.intercomStatus = intercomStatus;
          this.newIntercomStatus = JSON.parse(JSON.stringify(intercomStatus));
        },
        error: () => this.displayLocalizedErrorNotification('Preferences.ApiGetIntercomStatusError')
      });
  }

  /**
   * Checks if the preferences have changed.
   * @param current the current preferences
   * @param newPreferences the new preferences
   * @returns true if the preferences have changed, false otherwise.
   */
  private hasPreferencesChanged(current: any, newPreferences: any): boolean {
    return current.tones_volume !== newPreferences.sounds.tones_volume ||
      current.ringtones_volume !== newPreferences.sounds.ringtones_volume ||
      current.calls_volume !== newPreferences.calls.volume;
  }

  /**
   * Creates the content to update the intercom preferences.
   * @returns The content to be sent to the API to update the intercom preferences.
   */
  private createUpdatePreferencesContent() {
    return {
      "audio": {
        "sounds": {
          "tones_volume": this.newIntercomStatus!.device.preferences!.audio!.sounds!.tones_volume,
          "ringtones_volume": this.newIntercomStatus!.device.preferences!.audio!.sounds!.ringtones_volume,
          "call_ringtone": this.newIntercomStatus!.device.preferences!.audio!.sounds!.call_ringtone,
          "floor_call_ringtone": this.newIntercomStatus!.device.preferences!.audio!.sounds!.floor_call_ringtone
        },
        "calls": {
          "volume": this.newIntercomStatus!.device.preferences!.audio!.calls!.volume
        }
      }
    }
  }

}
