import { Pipe, PipeTransform } from '@angular/core';
import { AccessLevel } from '../../shared/models/users/access-level';

@Pipe({ name: 'accessLevelTitle' })
export class AccessLevelTitlePipe implements PipeTransform {

    transform(value: AccessLevel): string {
        switch (value) {
            case AccessLevel.INSTALLER_USER:
            case AccessLevel.INSTALLER:
                return 'General.Installer';
            case AccessLevel.MANAGER_USER:
            case AccessLevel.MANAGER:
                return 'General.PropertyManager';
            case AccessLevel.ADMIN_USER:
                return 'General.OwnerUser';
            case AccessLevel.GUEST_USER:
                return 'General.GuestUser';
            default:
                return 'Unknown';
        }
    }

}
