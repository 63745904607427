import { Injectable } from "@angular/core";
import { ApplicationVersion, ServerApplicationVersion } from "../models/application-version";
import { BaseApiService } from "../utils/base-api-service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppInfoService extends BaseApiService {
  getApplicationVersion(): ApplicationVersion {
    return {
      runtime: "Angular 18",
      version: "v.1.0.1",
      releaseDate: "13/01/2024 11:00:00",
    } as ApplicationVersion;
  }

  getServerVersion(): Observable<ServerApplicationVersion> {
    return this.get("/app_info");
  }

  public get title() {
    return "IP Evo";
  }
}
