<div id="unit-page" class="page">
  <app-breadcrumb
    [items]="breadcrumbItems"
    [showAreaPlaceholder]="isBusy"
  ></app-breadcrumb>

  <app-page-header
    [title]="unit?.name"
    [locationInfo]="unit?.location"
    locationInfoIcon="assets/icons/ic_marker.svg"
    [info]="unit?.notes"
    infoIcon="assets/icons/ic_info_outlined.svg"
    [showAreaPlaceholder]="isBusy"
  >
    <dx-button
      type="default"
      icon="edit"
      [text]="'Units.EditUnitDetails' | i18next"
      (click)="editUnitDetails()"
    ></dx-button>
  </app-page-header>

  <app-widget-card
    icon="assets/icons/ic_device.svg"
    [title]="'General.Devices' | i18next"
    [showAreaPlaceholder]="isBusy"
    minHeight="500px"
  >
    <dx-data-grid [dataSource]="unit?.devices || []" [hoverStateEnabled]="true">
      <dxi-column dataField="name"> </dxi-column>
      <dxi-column
        [caption]="'General.Outputs' | i18next"
        cellTemplate="templateOutputs"
      >
        <div *dxTemplate="let cellInfo of 'templateOutputs'">
          {{ cellInfo.data | farfisaDeviceToOutputsString }}
        </div>
      </dxi-column>

      <dxi-column
        dataField="product_line"
        [caption]="'General.DeviceLine' | i18next"
        alignment="left"
        cellTemplate="productLineTemplate"
      >
        <div *dxTemplate="let data of 'productLineTemplate'">
          {{ data.value | productLineName }}
        </div>
      </dxi-column>
      <dxi-column
        name="product-type"
        dataField="product_line"
        [caption]="'General.DeviceType' | i18next"
        alignment="left"
        cellTemplate="productTypeTemplate"
      >
        <div *dxTemplate="let data of 'productTypeTemplate'">
          {{ data.value | productTypeName }}
        </div>
      </dxi-column>

      <dxi-column
        dataField="thing_name"
        [caption]="'General.SerialNumber' | i18next"
        cellTemplate="serialNumberTemplate"
      >
        <div *dxTemplate="let data of 'serialNumberTemplate'">
          {{ data.value | thingNameToSerialNumber }}
        </div>
      </dxi-column>
    </dx-data-grid>
  </app-widget-card>

  <div class="two-column-layout">
    <div class="left-column">
      <app-widget-card
        icon="assets/icons/ic_account.svg"
        actionIcon="add"
        [title]="'General.Users' | i18next"
        [action]="'Units.InviteUser' | i18next"
        (actionClick)="createUserInvitation()"
        [showAreaPlaceholder]="isBusy"
        minHeight="500px"
      >
        <dx-data-grid
          [dataSource]="unitUsersAndInvitations"
          [hoverStateEnabled]="true"
          (onRowClick)="editUserOrInvitation($event)"
          id="users-grid"
        >
          <dxo-paging [pageSize]="4"></dxo-paging>

          <dxi-column
            [caption]="'Users.ContactInfo' | i18next"
            cellTemplate="emailCellTemplate"
          >
            <app-label
              [text]="data.data.email"
              [color]="data.data.color"
              *dxTemplate="let data of 'emailCellTemplate'"
            ></app-label>
          </dxi-column>

          <dxi-column
            dataField="devices"
            [caption]="'Users.AccessTo' | i18next"
            cellTemplate="templateDevices"
          >
            <div
              [title]="data.value | unitDevicesToString : installation?.devices"
              *dxTemplate="let data of 'templateDevices'"
            >
              {{ data.value | unitDevicesToString }}
            </div>
          </dxi-column>

          <dxi-column
            [caption]="'Users.AccessLevel' | i18next"
            cellTemplate="templateAccessLevel"
            alignment="center"
          >
            <div *dxTemplate="let data of 'templateAccessLevel'">
              {{ data.data | userInvitationAccess | i18next }}
            </div>
          </dxi-column>
        </dx-data-grid>
      </app-widget-card>
    </div>

    <div class="right-column">
      <app-widget-card
        icon="assets/icons/ic_account.svg"
        [title]="'General.Intercoms' | i18next"
        [showAreaPlaceholder]="isBusy"
        minHeight="500px"
        (onRowClick)="navigateToIntercomDetails($event)"
      >
        <dx-data-grid
          [dataSource]="unit?.intercoms || []"
          [hoverStateEnabled]="true"
        >
          <dxi-column dataField="name" [caption]="'General.Name' | i18next">
          </dxi-column>
          <dxi-column
            dataField="description"
            [caption]="'General.Description' | i18next"
          >
          </dxi-column>
          <dxi-column
            dataField="thing_name"
            [caption]="'General.SerialNumber' | i18next"
            cellTemplate="serialNumberTemplate"
          >
            <div *dxTemplate="let data of 'serialNumberTemplate'">
              {{ data.value | thingNameToSerialNumber }}
            </div>
          </dxi-column>
        </dx-data-grid>
      </app-widget-card>
    </div>
  </div>
</div>

<app-shimmer-overlay *ngIf="isBusy"></app-shimmer-overlay>
