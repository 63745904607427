<div id="sidebar-header" class="with-image" [style.background-image]="'url(' + installation?.image_url + ')'">
    <div class="overlay">
        <div class="overlay-title">
            {{installation?.name}}
        </div>
        <div class="overlay-subtitle">
            {{installation?.address?.address}}
        </div>
    </div>
</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">Building</div>

    <div class="sidebar-item" (click)="openUserLicenses()">
        <svg-icon src="assets/icons/ic_license.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">{{"Installations.Licenses" | i18next}}</div>
    </div>

</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">{{"General.Managers" | i18next}}</div>

    <div class="label-container vspace">
        @for (item of users; track item; let  i = $index) {    
            <app-label [text]="item.email | noDomainEmail" [color]="i | labelBackground"></app-label>
        }
    </div>

    <div class="sidebar-item" (click)="createManagerInvitationClicked()">
        <svg-icon src="assets/icons/ic_add_action.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text sidebar-item-text-action">{{"Invitations.InviteManager" | i18next}}</div>
    </div>

</div>


<div class="sidebar-section">

    <div class="sidebar-item" (click)="installationDisassociateClicked()">
        <svg-icon src="assets/icons/ic_trash.svg" class="sidebar-item-icon error"></svg-icon>
        <div class="sidebar-item-text sidebar-item-text-action error">{{"Installations.Disassociate" | i18next}}</div>
    </div>

</div>