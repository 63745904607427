import { Component, Input } from "@angular/core";
import { BaseDialogContent } from "../../dialogs/base-dialog-component";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { openCloseAnimation } from "../../dialogs/animations/show-hide.animation";
import { Installation } from "../../shared/models/installations/installation";
import { LicensesManagerService } from "../licenses-manager.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-create-license-dialog",
  templateUrl: "./create-license-dialog.component.html",
  styleUrl: "./create-license-dialog.component.scss",
  animations: [openCloseAnimation],
})
export class CreateLicenseDialogComponent extends BaseDialogContent {
  @Input()
  installation?: Installation;

  licenseKey: string = "";

  canAddKey = false;

  constructor(
    private licenses: LicensesManagerService,
    dialogs: DialogsService
  ) {
    super(dialogs);
  }

  override passData(data: any): void {
    this.installation = data as Installation;
  }

  validateForm() {
    this.canAddKey = this.licenseKey?.trim().length > 0;
  }

  addLicense() {
    if (!this.installation || !this.installation.id) {
      console.error("Installation is missing.");
      return;
    }

    this.isBusy = true;
    this.licenses
      .activateLicense(this.installation.id, this.licenseKey)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (_) => this.handleAPIResponse(true),
        error: (_) => this.handleAPIResponse(false),
      });
  }

  private handleAPIResponse(success: boolean) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(
        "Installations.ApiActivateLicenseSuccess"
      );
      this.dialogs.sendDialogResult(true);
    } else {
      this.dialogs.displayErrorNotification(
        "Installations.ApiActivateLicenseError"
      );
    }
  }
}
